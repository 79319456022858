<template>
  <!-- IOS视频通话中转页面 -->
  <div class="body-main">
    暂时不用这个页面
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style lang="less" scoped>
.body-main{






}


</style>